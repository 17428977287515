import { Disqus } from "vue-disqus";
import axios from "axios";
import { CldContext, CldImage } from "cloudinary-vue";

export default {
  name: "detailsBlogSection",
  components: {
    Disqus,
    CldContext,
    CldImage,
  },
  data() {
    return {
      facebookShareLink:
        "https://www.facebook.com/sharer/sharer.php?u=" +window.location.href +"/",
      twitterShareLink:
        "https://twitter.com/intent/tweet?text=" + window.location.href,
      linkedinShareLink:
        "https://www.linkedin.com/cws/share?url=" + window.location.href,
      redditShareLink:
        "http://www.reddit.com/submit?url=" + window.location.href,
      mailtoBlog:
        "mailto:?subject=Giftstory Blog&amp;amp;body=" + window.location.href,
      windowLink: window.location.href,
      elementBlogData: [],
      pageConfig: {
        identifier: this.$route.path + this.$route.query.blog,
        url: window.location.href,
      },
    };
  },
  // metaInfo() {
  //   return {
  //     title: "GiftStory-blog" + this.elementBlogData.title,
  //     meta: [
  //       {
  //         property: "og:description",
  //         content: this.elementBlogData.briefDescription,
  //       },
  //       { property: "og:url", content: window.location.href },
  //       {
  //         property: "og:image",
  //         content:
  //           "https://res.cloudinary.com/dpy824jnw/image/upload/" +
  //           this.elementBlogData.imgLink,
  //       },
  //     ],
  //   };
  // },
  created() {
    console.log("tete123", this.aws_url);
    axios.interceptors.request.use(
      (config) => {
        console.log("Request was sent");
        this.$loading(true);
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axios
      .get(
        "https://giftstore-blog.herokuapp.com/api/article/" + this.getIdUrl()
      )
      .then((response) => {
        this.elementBlogData = response.data.articles;
        // this.metaShare();
        this.$loading(false);
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },

  methods: {
    // metaShare() {
    //   // var x = document.querySelector("html");
    //   // document.querySelector("html").innerHTML=x
    //   // document.location.reload();
    //   // var meta = document.createElement("meta");
    //   // meta.setAttribute("property", "og:image");
    //   // meta.setAttribute(
    //   //   "content",
    //   //   "https://res.cloudinary.com/dpy824jnw/image/upload/" +
    //   //     this.elementBlogData.imgLink
    //   // );
    //   // console.log("meta", meta);
    //   // document.getElementsByTagName("head")[0].appendChild(meta);
    //   // document.querySelector('meta[name="description"]').setAttribute("content", "https://res.cloudinary.com/dpy824jnw/image/upload/" + this.elementBlogData.imgLink);
    // },

    DateBlogFormat(Fulldate) {
      var fullDateFormat = new Date(Fulldate);
      console.log(fullDateFormat);
      var day = fullDateFormat.getDate();
      var mm = fullDateFormat.getMonth() + 1;
      var month;
      switch (mm) {
        case 1:
          month = "Janvier";
          break;
        case 2:
          month = "Février";
          break;
        case 3:
          month = "Mars";
          break;
        case 4:
          month = "Avril";
          break;
        case 5:
          month = "Mai";
          break;
        case 6:
          month = "Juin";
          break;
        case 7:
          month = "Juillet";
          break;
        case 8:
          month = "août";
          break;
        case 9:
          month = "septembre";
          break;
        case 10:
          month = "Octobre";
          break;
        case 11:
          month = "Novembre";
          break;
        case 12:
          month = "Décembre";
          break;
      }
      var years = fullDateFormat.getFullYear();
      var dateResult = " " + month + " " + day + ", " + years;
      return dateResult;
    },
    getIdUrl() {
      return this.$route.query.blog;
    },
  },
};
