import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import store from "./store";
import "aos/dist/aos.css";
import VueDisqus from "vue-disqus";
import VueLoading from "vuejs-loading-plugin";
import * as VueSpinnersCss from "vue-spinners-css";
import VueAnalytics from "vue-analytics";
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.config.productionTip = false;
Vue.use(VueAnalytics, {
  id: "UA-181039951-1",
  router
}),
  // Vue.use(VueLoading)
  Vue.use(VueSpinnersCss);
Vue.use(VueLoading, {
  dark: true, // default false
  text: "Chargement", // default 'Loading'
  loading: true, // default false
  customLoader: VueSpinnersCss.HeartLoader, // replaces the spinner and text with your own
  background: "rgb(255,255,255,0.9)", // set custom background
  classes: ["spinner-color"], // array, object or string
});

new Vue({
  created() {
    AOS.init({ disable: "phone" });
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
Vue.use(VueDisqus);
