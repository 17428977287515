import axios from "axios";
import { CldContext, CldImage } from "cloudinary-vue";

export default {
  name: "elementBlogSection",
  components: {
    CldContext,
    CldImage,
  },
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    // declare a request interceptor
    axios.interceptors.request.use(config => {
      console.log('Request was sent');
      this.$loading(true)
      return config;
    }, error => {
      return Promise.reject(error);
    });
        
    axios
      .get("https://giftstore-blog.herokuapp.com/api/article/")
      .then((response) => {
        console.log(response);
        this.tableData = response.data.articles;
        this.$loading(false)
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },
  methods: {
    // classDiv(i) {
    //   if (((i + 1) % 3 ) == 0) {
    //     return "col-md-12";
    //   } else {
    //     return "col-md-6";
    //   }
    // },
    DateBlogFormat(Fulldate) {
      var fullDateFormat = new Date(Fulldate);
      console.log(fullDateFormat);
      var day = fullDateFormat.getDate();
      var mm = fullDateFormat.getMonth() + 1;
      var month;
      switch (mm) {
        case 1:
          month = "Janvier";
          break;
        case 2:
          month = "Février";
          break;
        case 3:
          month = "Mars";
          break;
        case 4:
          month = "Avril";
          break;
        case 5:
          month = "Mai";
          break;
        case 6:
          month = "Juin";
          break;
        case 7:
          month = "Juillet";
          break;
        case 8:
          month = "août";
          break;
        case 9:
          month = "septembre";
          break;
        case 10:
          month = "Octobre";
          break;
        case 11:
          month = "Novembre";
          break;
        case 12:
          month = "Décembre";
          break;
      }
      var years = fullDateFormat.getFullYear();
      var dateResult = " " + month + " " + day + ", " + years;
      return dateResult;
    },
  },
};
