<template>
  <div>
    <heroSection></heroSection>
    <informationSection></informationSection>
    <elementBlogSection></elementBlogSection>
    <joinListSection></joinListSection>
  </div>
</template>

<script>
// @ is an alias to /src
import heroSection from "@/components/heroSection/index.vue";
import informationSection from "@/components/informationSection/index.vue";
import elementBlogSection from "@/components/elementBlogSection/index.vue";
import joinListSection from "@/components/joinListSection/index.vue";



export default {
  name: "Home",
  components: {
    heroSection,
    informationSection,
    elementBlogSection,
    joinListSection
  }
};
</script>
