<template>
  <div id="app">
      <navbarSection></navbarSection>
      <router-view />
      <footerSection></footerSection>
  </div>
</template>

<script>
import navbarSection from "./layouts/navbarSection/index.vue";
import footerSection from "./layouts/footerSection/index.vue";
export default {
  name: "App",
  components: {
    navbarSection,
    footerSection
  },
  //   metaInfo() {
  //   return {
  //     title: "test meta data with vue",
  //     meta: [
  //       {
  //         vmid: "description",
  //         name: "description",
  //         content:
  //           "hello world, this is an example of adding a description with vueMeta"
  //       }
  //     ]
  //   };
  // }
  
}
</script>
