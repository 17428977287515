<template>
  <div>
    <heroSection></heroSection>
   <contactSection></contactSection>
  </div>
</template>

<script>
// @ is an alias to /src
import heroSection from "@/components/heroSection/index.vue";
import contactSection from "@/components/contactSection/index.vue";



export default {
  name: "Home",
  components: {
    heroSection,
    contactSection
    },
  created() {
    this.$loading(false);
  },
};
</script>
