<template>
  <div>
    <heroSection></heroSection>
    <aboutSection></aboutSection>
  </div>
</template>

<script>
// @ is an alias to /src
import heroSection from "@/components/heroSection/index.vue";
import aboutSection from "@/components/aboutSection/index.vue";

export default {
  name: "Home",
  components: {
    heroSection,
    aboutSection,
  },
  created() {
    this.$loading(false);
  },
};
</script>
