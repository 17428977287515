<template>
  <div>
    <heroSection></heroSection>
    <detailsBlogSection></detailsBlogSection>
  </div>
</template>

<script>
// @ is an alias to /src
import heroSection from "@/components/heroSection/index.vue";
import detailsBlogSection from "@/components/detailsBlogSection/index.vue";



export default {
  name: "Details-blog",
  components: {
    heroSection,
    detailsBlogSection,
  }
};
</script>
