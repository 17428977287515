export default {
  name: "joinListSection",
  data() {
    return {
      showBtn: true,
      messageStat: 0,
    };
  },
  methods: {
    onSubmitFormSubscribe(ev) {
      ev.preventDefault();
      const form = ev.target;
      const data = new FormData(form);
      const xhr = new XMLHttpRequest();
      xhr.open(form.method, form.action);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          form.reset();
          this.showBtn = false;
          this.messageStat=1;
        } else {
          this.messageStat=2;
        }
      };
      xhr.send(data);
    },
  },
};
