import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import DetailsBlog from "../views/Details-blog.vue";
import about from "../views/about.vue";
import contact from "../views/contact.vue";
import confidentialite from "../views/confidentialite.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/details-blog",
    name: "DetailsBlog",
    component: DetailsBlog
  },
  {
    path: "/about",
    name: "about",
    component: about
  },
  {
    path: "/contact",
    name: "contact",
    component: contact
  },
  {
    path: "/confidentialite",
    name: "confidentialite",
    component: confidentialite
  },
  
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
