<template>
  <div>
    <heroSection></heroSection>
    <confidentialiteSection></confidentialiteSection>
  </div>
</template>

<script>
// @ is an alias to /src
import heroSection from "@/components/heroSection/index.vue";
import confidentialiteSection from "@/components/confidentialiteSection/index.vue";




export default {
  name: "confidentialite",
  components: {
    heroSection,
    confidentialiteSection
  },
    created() {
    this.$loading(false);
  },
};
</script>
